import './App.css';
import React, { useState, useEffect } from 'react';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import { Area, AreaChart, Label, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';


function App() {
  const [domains, setDomains] = useState([]);
  const [traffic, setTraffic] = useState(null);
  const [attacks, setAttacks] = useState(null);
  const [attacksData, setAttacksData] = useState(null);
  const [trafficData, setTrafficData] = useState(null);
  const columns = [
    { name: 'rank', header: 'Rank', defaultFlex: 1, render: ({ value }) => value + 1, type: 'number' },
    { name: 'domain', header: 'Domain', defaultFlex: 1 },
    { name: 'rankChange', header: 'Rank Change', defaultFlex: 1, type: 'number' },
    { name: 'category', header: 'Category', defaultFlex: 1 }
  ]

  useEffect(() => {
    fetch("https://workers.yifan-workers.workers.dev/popular-domains")
      .then(response => response.json())
      .then(data => {
        setDomains((data['rankingEntries']));
      });

    fetch("https://workers.yifan-workers.workers.dev/traffic-change")
      .then(response => response.json())
      .then(data => {
        setTraffic((data));
      });

    fetch("https://workers.yifan-workers.workers.dev/attack-layer3")
      .then(response => response.json())
      .then(data => {
        setAttacks((data));
      });
  }, []);

  useEffect(() => {
    if (attacks) {
      const attacksData = attacks.data.total.timestamps.map((stamp, i) => {
        return {
          timestamps: stamp,
          values: attacks.data.total.values[i] * 100
        }
      })
      setAttacksData(attacksData);
    }
  }, [attacks]);

  useEffect(() => {
    if (traffic) {
      const trafficData = traffic.data.total.timestamps.map((stamp, i) => {
        return {
          timestamps: stamp,
          values: traffic.data.total.values[i]
        }
      })
      setTrafficData(trafficData);
      console.log(trafficData);
    }
  }, [traffic]);

  return (
    <div className="App">
      <section className="traffic-change">
        <h1>traffic-change</h1>
        <div style={{ "width": "90%", "height": "500px", "margin": "0 auto" }}  >
          <ResponsiveContainer>
            <AreaChart data={trafficData}>
              <XAxis dataKey="timestamps" scale={'band'} tick={false} >
                <Label value="time" offset={0} position="insideBottom" style={{
                  textAnchor: 'middle',
                  fontSize: '100%',
                  fill: 'black',
                }} />
              </XAxis>
              <YAxis tick={false}>
                <Label
                  style={{
                    textAnchor: "top",
                    fontSize: "100%",
                    fill: "black",
                  }}
                  angle={270}
                  value={"traffic change"} />
              </YAxis>
              <Area type="monotone" dataKey="values" stroke="#2C7CB0" fillOpacity={1} fill="url(#colorT)" />
              <Tooltip />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </section>
      <section className="popular-domains">
        <div style={{ "width": "90%", "height": "800px", "margin": "0 auto" }}  >
          <h1>popular-domains</h1>
          <ReactDataGrid
            idProperty="domain"
            columns={columns}
            defaultSortInfo={{ name: 'rank', dir: 1, type: 'number' }}
            dataSource={domains}
            style={{ minHeight: 700 }}
            theme="default-light"
          />
        </div  >

      </section>
      <section className="attack-layer3">
        <h1>attack-layer3</h1>
        <div style={{ "width": "90%", "height": "500px", "margin": "0 auto" }}  >
          <ResponsiveContainer>
            <AreaChart data={attacksData}>
              <XAxis dataKey="timestamps" scale={'band'} tick={false} >
                <Label value="time" offset={0} position="insideBottom" style={{
                  textAnchor: 'middle',
                  fontSize: '100%',
                  fill: 'black',
                }} />
              </XAxis>
              <YAxis tick={false}>
                <Label
                  style={{
                    textAnchor: "top",
                    fontSize: "100%",
                    fill: "black",
                  }}
                  angle={270}
                  value={"attack%"} />
              </YAxis>
              <Area type="monotone" dataKey="values" stroke="#DA304C" fillOpacity={1} fill="url(#colorUv)" />
              <Tooltip />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </section>

    </div>
  );
}

export default App;
